.backdrop {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.popupContainer {
  position: relative;
  width: 15em;
  height: 15em;
  background-color: #ffffff;
}

.closeContainer {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  font-size: 1.5em;
}

.popupContainer img {
  width: 100%;
  height: 100%;
}

.invisible {
  display: none;
}
