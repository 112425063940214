.form {
  padding: 1em;
  font-family: sans-serif;
  letter-spacing: -0.8px;
  max-width: 1080px;
  margin: 0 auto;
}

.formInputContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6%;
}

.formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formInput .error {
  color: red;
  font-size: 0.8em;
  margin-top: 0.2em;
}

.formInput label,
.form > div > p {
  font-size: 1em;
  margin: 0.3em 0;
  font-weight: bold;
}

.formInput input,
.formInput select {
  width: 100%;
  height: 2em;
  background-color: transparent;
  border: 2px solid var(--pink-border-color);
  font-size: 1em;
  padding-left: 0.5em;
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.formInput select {
  border-radius: 0;
  /* 화살표 이미지 svg */
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' height='10px' width='1em'%3E%3Ctext x='0' y='10' fill='gray'%3E%E2%96%BE%3C/text%3E%3C/svg%3E")
    no-repeat 98% 50% transparent;
}

.form > div {
  margin: 1em 0;
}

.required::after {
  content: '*';
}

.formCheckbox label {
  margin-right: 0.5em;
}

.formCheckbox > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.formCheckbox > div > div {
  margin: 0.5em;
}

.form input[type='checkbox'] {
  display: none;
}

.form input[type='checkbox'] + label {
  position: relative;
  display: inline-block;
  padding-left: 1.5em;
  cursor: pointer;
}

.form input[type='checkbox'] + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  background-color: transparent;
  border: 2px solid var(--pink-border-color);
}

.form input[type='checkbox']:checked + label:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  background-color: var(--pink-border-color);
}

.privacy {
  margin-top: 0.5em;
  font-size: 0.9em;
}

.buttons {
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 700px) {
  .formInput {
    width: 47%;
  }
}
