.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 1em 1em;
  background-color: transparent;
  transition: all 0.5s linear;
}

.container {
  display: flex;
  align-items: center;
}

.logoContainer {
  width: 5em;
}

.logoContainer img {
  max-width: 100%;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  font-family: sans-serif;
  color: #ffffff;
  font-weight: bold;
  font-size: 0.8em;
  height: 2em;
}

.navigation a {
  position: relative;
  margin-left: 1em;
  color: inherit;
  text-decoration: none;
}

.navigation .contactItem {
  background-color: var(--main-theme-color);
  border-radius: 2em;
  padding: 0.2em 0.8em;
  color: #ffffff;
}

.scrolled {
  background-color: #ffffff;
  border-bottom: 1px solid var(--gray-bg-color);
  transition: all 0.5s linear top 2s;
}

.scrolled .navigation a {
  color: #000000;
}

.selected::after {
  content: '';
  display: block;
  border-top: 1px solid;
  animation: 0.3s ease border;
}

.navDetail {
  position: absolute;
  top: 1.2em;
  right: 0;
  width: 6em;
  text-align: right;
  font-size: 0.7em;
}

@keyframes border {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}
