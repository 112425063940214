.footer {
  width: 100%;
  background-color: var(--lightgray-bg-color);
  color: 0;
  text-align: left;
  padding: 3em 1em;
  box-sizing: border-box;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.logo {
  max-width: 8em;
  margin-bottom: 2em;
}

.tel {
  font-size: 2.5em;
  font-weight: bold;
  text-decoration: none;
  color: #000000;
  margin: 2em 0;
}
.tel2 {
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
  color: #000000;
  margin: 2em 0;
}

.biz,
.contact {
  margin-top: 2em;
  margin-bottom: 1em;
}

.sns {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  text-align: right;
  margin: 2em 0;
}

.sns a {
  font-size: 2.5em;
}

.sns img {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  max-height: 1em;
}
