.contact h1,
.contact h2,
.contact p {
  margin: 0;
}

.contact a {
  text-decoration: none;
  color: inherit;
}

.contact p {
  font-family: sans-serif;
}

.contact img {
  max-width: 100%;
}

.contact h1::first-line {
  font-size: 0.7em;
}

.contact h1 {
  color: #ffffff;
  text-align: center;
}

.headerContainer {
  width: 100%;
  height: 20em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(assets/img/contact_banner.jpg);
  background-size: cover;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.3);
  background-position: center;
  font-family: sans-serif;
}

.contentContainer {
  padding: 1em;
}

.contentContainer > p {
  padding: 0.5em;
}

.contactOrder {
  position: relative;
  display: flex;
  font-family: sans-serif;
  margin: 1em 0;
}

.contactOrder::after {
  content: '';
  position: absolute;
  top: 1.7em;
  left: 10%;
  width: 80%;
  border-top: 2px solid #ffacac;
  z-index: -1;
}

.contactItem {
  flex: 1;
  text-align: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2em;
  height: 3.2em;
  padding: 0.9em;
  background-color: #ffacac;
  border-radius: 100px;
  margin: 0 auto;
}

.contactItem:first-child .icon img {
  width: 2.5em;
  height: 1.5em;
  max-width: 2.5em;
  margin-left: 0.3em;
}

.contactItem p {
  margin-top: 0.5em;
  font-size: 0.8em;
  word-break: keep-all;
}

.formContainer {
  background-color: var(--pink-bg-color);
}

.formContainer ContactForm {
  margin: 0 auto;
  max-width: 1080px;
}
