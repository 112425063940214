.insightItem a {
  text-decoration: none;
  color: inherit;
}

.insightItem img {
  height: 100%;
  width: 100%;
}

.thumbnail {
  /* height: 10em; */
  /* max-width: calc(100vw / 3 - 1em);
  max-height: calc((100vw / 3) - 1em); */
}

.title {
  word-break: keep-all;
  margin: 0;
  padding: 0.5em 0 0 0;
}

.subtitle {
  font-size: 0.8em;
  color: var(--main-theme-color);
  font-family: 'GmarketSans';
  font-weight: 100;
}

@media screen and (min-width: 1080px) {
  .cardContent .thumbnail {
    width: calc(1080px / 3 - 1em);
    max-height: calc((1080px / 3) - 1em);
  }
}
