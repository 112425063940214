.bannerContainer {
  position: relative;
  height: 100vh;
  padding: 0 !important;
  /* max-width: 1080px; */
}

.bannerImg {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 100%;
  max-height: 100vh;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.4);
}

.titleContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5%;
  left: 10%;
  width: 80%;
  height: 90%;
  z-index: 100;
}

.title::first-line {
  font-size: 0.5em;
}

.title {
  font-family: sans-serif;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  font-size: 3em;
  width: 100%;
}
