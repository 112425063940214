.site p {
  margin: 0;
}

.site img {
  width: 100%;
}

.site h3 {
  margin: 0;
  font-weight: bold;
  font-size: 1em;
  line-height: 1.5;
}

.site header {
  font-family: sans-serif;
  font-weight: bold;
  width: 100vw;
  height: 20em;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-size: cover;
  background-image: url('assets/img/main_banner1.jpg');
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  background-position: center;
}

.site header p {
  font-size: 1.3em;
}

.site header h1 {
  font-size: 2.3em;
  margin: 0;
}

.site section {
  padding: 2.5em 1em;
  background-color: white;
}

.site section h2 {
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 1em;
}

.site section.pinkBg {
  background-color: var(--pink-bg-color);
}

.contentBox {
  display: flex;
  font-family: sans-serif;
}

/* 이벤트 지점 */
.eventSiteItem {
  flex: 1;
  margin-right: 0.75em;
}

.eventSiteItem:last-of-type {
  margin-right: 0;
}

.imgSquareBox {
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 100%;
}

.eventSiteItem p {
  color: #ea0029;
  font-size: 0.8125em;
  letter-spacing: -0.05em;
  line-height: 1.5;
}

.eventSiteItem button {
  font-size: 0.8125em;
  padding: 0.3em 1em;
  margin-top: 0.75em;
}

/* 지점별 소개 */
.siteList {
  margin-top: 2.5em;
}

.siteItem {
  flex-direction: column;
  padding-bottom: 2em;
  margin-left: 1em;
  padding-left: 1em;
  border-left: 2px solid black;
}

.siteItem h3 {
  position: relative;
  margin-bottom: 0.25em;
}

.siteItem h3::before {
  position: absolute;
  content: '';
  height: 1em;
  width: 1em;
  top: 50%;
  transform: translateY(-50%);
  left: calc(-1.5em - 1px);
  box-sizing: border-box;
  border: 2px solid black;
  border-radius: 50%;
  background-color: white;
}

.siteItem:first-of-type {
  position: relative;
  margin-top: 1em;
  padding-top: 0.5em;
}

.siteItem:first-of-type h3 {
  position: absolute;
  top: -1em;
}

.siteItem:last-of-type {
  padding-bottom: 0;
}

.siteItemImg {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25em;
}

.siteItemImg .buttons {
  display: flex;
}

.siteItemImg button {
  font-size: 0.8125em;
  padding: 0.3em 1em;
  margin-top: 0.75em;
  margin-right: 1em;
}

.imgRectBox {
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-bottom: 56.25%;
}

.siteItemContent {
  font-size: 0.8125em;
}

.siteItemContent p {
  font-weight: bold;
  margin-bottom: 0.5em;
}

.siteItemContent ul {
  padding-left: 2em;
  margin: 0.5em 0;
  word-break: keep-all;
}

/* @media (min-width: 768px) { */
@media screen and (min-width: 700px) {
  .siteItem {
    border-left: 3px solid black;
  }

  .siteItem h3::before {
    left: calc(-1.5em - 1.5px);
    border: 3px solid black;
  }

  .siteItemImg {
    flex-direction: row;
  }

  .siteItemImg .buttons {
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 1em;
  }

  .siteItemImg button {
    font-size: 0.725em;
    width: 9.8em;
    margin-top: 1em;
    margin-right: 0;
  }

  .imgBox {
    padding-bottom: 50%;
  }
}
