.container {
  font-family: sans-serif;
  margin: 4em auto;
  padding: 0 1em;
}

.container h2,
.container h3,
.container p {
  margin: 0;
}

.container h2 {
  font-size: 1.4em;
}

.container h3 {
  font-size: 1.1em;
  color: var(--gray-font-color);
}

.container {
  font-family: sans-serif;
}

.date {
  text-align: right;
  font-size: 0.8em;
  color: var(--gray-font-color);
  font-size: 0.8em;
  border-bottom: 1px dashed var(--gray-font-color);
  padding: 1em 0;
}

.file {
  padding: 1em 0;
  font-size: 0.8em;
  border-bottom: 1px dashed var(--gray-font-color);
}

.file a {
  color: var(--gray-font-color);
}

.contentContainer p {
  margin: 0.5em 0;
}

.contentContainer {
  font-family: sans-serif;
  font-size: 1em;
  padding: 1em 0;
}

.contentContainer img {
  max-width: 100%;
}
