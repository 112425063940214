.container {
  margin-top: 3em;
  padding: 1em;
}

.container p,
.container h1,
.container h2 {
  margin: 0;
}

.containerHeader {
  font-size: 1.2em;
  margin-bottom: 1em;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  column-gap: 0.5em;
  row-gap: 0.5em;
}
