.insight a {
  text-decoration: none;
  color: inherit;
}

.insight p,
.insight h1,
.insight h2 {
  margin: 0;
}

.headerContainer {
  width: 100%;
  height: 20em;
  background-image: url(assets/img/insight_banner.jpg);
  background-size: cover;
  background-blend-mode: multiply;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerContainer h1::first-line {
  font-size: 0.7em;
}

.headerContainer h1 {
  width: 100%;
  font-family: sans-serif;
  font-size: 2.3em;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.card {
  padding: 0 1em;
  margin: 4em auto;
}

.cardHeader {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1em;
  font-size: 1.2em;
}

.cardHeader p {
  color: var(--main-theme-color);
  margin-left: auto;
  font-size: 0.8em;
}

.cardContent {
  display: flex;
  width: 100%;
  height: 100%;
}

.cardContent .thumbnail {
  max-width: calc(100vw / 3 - 1em);
  height: calc((100vw / 3) - 1em);
}

.cardContent img {
  height: 100%;
  width: 100%;
}

.cardContent > div {
  flex: 1;
  height: 100%;
  margin-right: 0.3em;
}

.cardContent div:last-child {
  margin-right: 0em;
}

.title {
  word-break: keep-all;
  padding: 0.5em 0 0 0;
}

.subtitle {
  font-size: 0.8em;
  color: var(--main-theme-color);
  font-family: 'GmarketSans';
  font-weight: 100;
}

@media screen and (min-width: 1080px) {
  .cardContent .thumbnail {
    width: calc(1080px / 3 - 1em);
    max-height: calc((1080px / 3) - 1em);
  }
}
