.pinkBg {
  background-color: var(--pink-bg-color);
}

.grayBg {
  background-color: var(--gray-bg-color);
}

.siteInfo {
  font-family: sans-serif;
}

.siteInfo img {
  width: 100%;
}

.siteInfo header {
  font-weight: bold;
  width: 100vw;
  height: 20em;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  background-position: center;
}

.siteInfo section {
  padding-bottom: 2em;
}

.siteInfo .intro {
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  background-position: center;

  color: white;
  font-family: sans-serif;
  padding: 1.5em 1em;
  font-size: 1.3em;
}

.introTitle {
  text-align: center;
}
.introTitle .yellow {
  color: var(--yellow-color);
}

.introSubTitle {
  color: var(--yellow-color);
  margin-top: 1em;
  border-top: 1px solid white;
  padding-top: 1em;
  text-align: right;
}

.contentTitle {
  padding: 3em 1em 0.5em;
}

.contentTitle strong {
  font-size: 1.5em;
}

.contentTitle p {
  font-family: 'GmarketSans';
  font-weight: lighter;
}

.contentTitle b {
  font-weight: normal;
}

.bestAreaImgBox {
  position: relative;
}

.contentWithImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  text-align: center;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentWithImg h3 {
  margin: 0.25em 0 0;
}

.areaInfoList {
  display: flex;
  justify-content: space-around;
  font-size: 0.875em;
}

.areaInfoItem h4 {
  border-radius: 1.5em;
  border: 1px solid white;
  padding: 0.5em;
  margin: 1.5em 0 0;
}

.areaInfoItem h5 {
  font-size: 0.65625em;
  border-radius: 3em;
  border: 1px solid white;
  padding: 0.5em 1.5em;
  margin: 2.25em 0 0;
}

.areaInfoItem p {
  font-weight: bold;
}

.halfContentBox {
  padding: 1em;
  display: flex;
  justify-content: space-around;
  gap: 1em;
  text-align: center;
  font-size: 0.75em;
  word-break: keep-all;
}

.priceList {
  text-align: center;
  padding: 1em 0;
}

.priceList strong {
  display: inline-block;
  background-color: var(--main-theme-color);
  padding: 0.25em 1em;
  border-radius: 2em;
  color: white;
  margin-bottom: 1em;
}

.priceList p {
  max-width: 70%;
  margin: auto;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
}

.priceList span:last-of-type {
  text-align: right;
}

.whiteBg {
  background-color: white;
}

.imgList {
  padding: 0 1em;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  grid-column-gap: 0.25em;
  grid-row-gap: 0.25em;
}

.imgBox {
  padding: 1em;
}

.buttonContainer {
  text-align: center;
  margin: 1em;
}
