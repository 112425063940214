:root {
  --main-theme-color: #ea0029;
  --yellow-color: #ffe600;
  --main-bg-color: #e5e5e5;
  --pink-bg-color: #fce9e9;
  --pink-border-color: #ffacac;
  --lightpink-bg-color: #fff9f8;
  --gray-bg-color: #efefef;
  --lightgray-bg-color: #f3f3f3;
  --gray-font-color: #727272;
}

*,
::before,
::after {
  box-sizing: border-box;
}

body {
  font-size: 16px;
  margin: 0;
  letter-spacing: -0.5px;
  font-family: 'GmarketSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'GmarketSans';
  src: url(./assets/font/GmarketSansTTFLight.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'GmarketSans';
  src: url(./assets/font/GmarketSansTTFMedium.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'GmarketSans';
  src: url(./assets/font/GmarketSansTTFBold.ttf) format('truetype');
  font-weight: 700;
}

@media screen and (min-width: 700px) {
  body {
    font-size: 32px;
  }
}

.maxWidth {
  max-width: 1080px;
  margin: 0 auto;
}
