.home {
  --swiper-theme-color: #ffffff;
}

.home p strong {
  font-size: 1.3em;
}

.home p {
  font-family: sans-serif;
}

.home h2 {
  font-weight: 100;
  letter-spacing: -1px;
}

.home img {
  display: block;
  max-width: 100%;
}

.home > div {
  padding: 1em;
}

.home section {
  overflow: hidden;
}

.textLarge {
  font-size: 1.3em;
}

.quotation {
  position: relative;
  width: 100%;
  max-width: 1080px;
  margin: 2em auto;
  text-align: center;
  font-family: sans-serif;
}

.quotation svg:first-child {
  position: absolute;
  top: -0.5em;
  left: 1em;
  color: var(--main-theme-color);
  font-size: 1em;
}

.quotation svg:last-child {
  position: absolute;
  bottom: -0.5em;
  right: 1em;
  color: var(--main-theme-color);
  font-size: 1em;
}

.caption {
  font-size: 0.8em;
  color: var(--gray-font-color);
  text-align: center;
  font-family: sans-serif;
}

.logoContainer {
  max-width: 1080px;
  margin: 1em auto;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 0.5em;
  column-gap: 0.5em;
  align-items: center;
  justify-items: center;
}

.logoContainer div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBox {
  font-family: sans-serif;
}

.contentNo {
  color: var(--yellow-color);
  font-size: 3em;
  padding: 0 0.3em;
  font-family: 'GmarketSans';
  font-weight: bold;
}

.contentHeader {
  background-color: var(--yellow-color);
  width: 100%;
  margin-top: -0.55em;
  font-size: 1.1em;
  font-weight: bold;
  padding: 0.8em 1em;
}

.contentBox {
  margin-bottom: 1em;
}

.contentText {
  padding: 1em;
  word-break: keep-all;
}

.contentImg {
  display: flex;
  width: 100%;
  padding: 0.5em;
}

.contentImg .contentImgCaption {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentImg .contentImgCaption img {
  max-width: 90%;
  border-radius: 1em;
}

.contentImg .contentImgCaption .contentCheckList {
  padding: 0 0.1em;
  margin-top: 0.3em;
  font-size: 0.8em;
}

.contentCheckList {
  color: var(--gray-font-color);
  padding: 1.2em;
}

.videoContainer {
  text-align: center;
  padding: 1em 0;
}

.videoContainer iframe {
  max-width: 100%;
  max-height: 20rem;
}

.buttonContainer {
  text-align: center;
  margin: 1em;
}

.svgContainer {
  width: 100%;
  max-width: 900px;
  padding: 1em;
  margin: 0 auto;
}

.backgroundPink {
  background-color: var(--pink-bg-color);
  /* padding: 1em; */
}

.textHighlight {
  font-weight: bold;
  color: var(--main-theme-color);
  text-decoration: underline;
}

.kitchenFloorPlan img {
  width: 80%;
  margin: 0 auto;
}

.movementOrder {
  position: relative;
  display: flex;
  width: 80%;
  margin: 1em auto;
  text-align: center;
}

.movementOrder div::before {
  content: '';
  position: absolute;
  top: -0.5em;
  width: 1em;
  height: 1em;
  margin-left: -0.5em;
  background-color: #ffffff;
  border-radius: 1em;
  border: 2px solid var(--main-theme-color);
}

.movementOrder div::after {
  content: '';
  position: absolute;
  top: 0;
  width: 20%;
  border-bottom: 2px solid var(--main-theme-color);
  z-index: -1;
}

.movementOrder div:last-child::after {
  display: none;
}

.movementOrder div {
  width: 100%;
  margin-top: 1em;
}

.movementOrder > div > p {
  width: 100%;
  margin: 0;
  font-size: 0.8em;
  word-break: keep-all;
  text-align: center;
  font-weight: normal;
}

.animateImage {
  position: relative;
  width: 4800px;
  height: 480px;
}

.brand_logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(assets/img/brand_logo.png);
  background-size: 2400px 480px;
  background-repeat: repeat-x;
  animation: 20s linear slide infinite;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-2400px);
  }
}

@media screen and (max-width: 800px) {
  .animateImage {
    width: 2400px;
    height: 240px;
  }

  .brand_logo {
    background-size: 1200px 240px;
  }

  @keyframes slide {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-1200px);
    }
  }
}
