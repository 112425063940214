.yellowBg {
  background-color: rgba(255, 230, 0, 0.5);
}

.grayBg {
  background-color: var(--gray-bg-color);
}

.siteInfoSimple {
  word-break: keep-all;
}

.siteInfoSimple img {
  width: 100%;
}

.siteInfoSimple header {
  font-weight: bold;
  width: 100vw;
  height: 20em;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-size: cover;
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  background-position: center;
}

.siteInfoSimple section {
  padding: 1.5em 1em;
}

.siteInfoSimple h2 {
  font-size: 1.25em;
}

.siteInfoSimple ul {
  padding-left: 1.5em;
}

.siteInfoSimple li,
.siteInfoSimple span {
  font-weight: 100;
  font-size: 0.875em;
  line-height: 1.5;
  margin-bottom: 0.125em;
  letter-spacing: -0.05em;
}

.flexBox {
  display: flex;
  gap: 0.5em;
  margin-bottom: 1em;
}

.topContent .flexBox {
  flex-direction: column;
}

.flexBox div:last-child img {
  visibility: hidden;
}

.movieContainer {
  flex: 1;
}
.movieContainer > div {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.movieContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sixth {
  width: 100%;
  background-size: cover;
  background-position: center;
}

.seventh {
  width: 66.66%;
  min-width: 66.66%;
}

.half {
  width: 55%;
  min-width: 55%;
}

.seventh p,
.half p {
  margin: 0 0 0.725em;
}

.buttonContainer {
  text-align: center;
  margin: 1em;
}

@media screen and (min-width: 700px) {
  .siteInfoSimple h2 {
    font-size: 1.5em;
  }

  .topContent .flexBox {
    flex-direction: row;
  }

  .sixth {
    min-width: 63%;
  }

  .flexBox div:last-child img {
    display: none;
  }
}
