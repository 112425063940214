.card {
  letter-spacing: -0.5px;
  font-family: sans-serif;
  margin: 1.5em auto;
  padding: 1.2em;
  text-align: center;
  background: #ffffff;
  border: 0.1em solid #f8f8f8;
  box-shadow: 0em 0.3em 0.3em rgba(0, 0, 0, 0.25);
  border-radius: 1em;
  max-width: 1080px;
}
