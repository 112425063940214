.result {
  padding: 7em 1em 3em;
  font-family: sans-serif;
}

.result table {
  margin: 0 auto;
  border-collapse: collapse;

}

.result table tr:nth-child(2n) {
  background-color: var(--pink-bg-color);
}

.result table td {
  padding: 0.3em 0.5em;
}

.result p {
  text-align: center;
}