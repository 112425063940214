.button {
  background-color: var(--main-theme-color);
  padding: 0.7em 2em;
  border: 0;
  border-radius: 5em;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  color: #fff;
  cursor: pointer;
}

.border {
  border: 2px solid var(--main-theme-color);
  background-color: white;
  color: black;
  cursor: default;
}

button:disabled {
  background-color: var(--gray-font-color);
}

button:active {
  opacity: 0.7;
}
